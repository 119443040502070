.App {
  padding: 1rem;
}

button {
  margin-bottom: 0.6rem;
  display: block;
}

section.overview {
  margin-bottom: 1.6rem;
}

section.playArea {
  display: grid;
  row-gap: 0.6rem;
  max-width: 58rem;
}

@media (min-width: 438px) {
  section.playArea {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 680px) {
  section.playArea {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1.2rem;
  }
}

@media (min-width: 892px) {
  section.playArea {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

h1 {
  font-size: 1.9rem;
  margin-bottom: 1.2rem;
}

h2 {
  margin-top: 0;
}

ul {
  padding-left: 1.2rem;
}